body {
  background-color: #102e4e;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: #FFFFFF;
}

.content-wrapper {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

#logo {
  max-width: 30%;
  height: auto;
}

#userFilter {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

#userFilter label, #userFilter button {
  margin-left: 2em;
}

#filterDate, #usersDropdown {
  padding: 10px 15px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
  font-size: 16px;
  outline: none;
  width: 200px; /* Or you can adjust based on your layout preference */
  transition: background-color 0.3s ease; /* For smooth color transition */
}

#usersDropdown option {
  background-color: rgba(16,46,78, 1);
}

button {
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(45deg, #206eca, #1f4f8a);
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease; /* For smooth gradient transition */
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

button:hover {
  background: linear-gradient(45deg, #1f4f8a, #206eca);
}

button:active {
  transform: scale(0.98); /* Gives a pressed effect */
}

/* This will provide some space between the form elements */
label {
  margin-right: 10px;
}

#data-table {
  border-collapse: collapse;
  width: 100%; 
  font-family: 'Roboto', sans-serif;
  margin: 1em 0;
}

#data-table th, #data-table td {
  padding: .5em 1em;
  text-align: left; 
  border-right: 2px solid #ddd;
}

#data-table th {
  border-bottom: 2px solid #ddd;
}

#data-table tbody tr:nth-child(odd) {
  background-color: rgba(128, 128, 128, 0.5);
}

#data-table td:last-child, #data-table th:last-child {
  border-right: none;
}
